import React from "react";
import {GlobalTransIntl} from "./GlobalTrans";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import jwtDecode from "jwt-decode";
import config from "../config/config";

export function inputOnlyInteger(e, setter, maxInteger = null) {
    const re = /^[1-9]\d*$/;

    if ((e.target.value === '' || re.test(e.target.value)) && (maxInteger === null || (e.target.value <= maxInteger))) {
        setter(e.target.value)
    }
}

export function inputOnlyIntegerAndZero(e, setter, maxInteger = null) {
    const re = /^[0-9]\d*$/;

    if ((e.target.value === '' || re.test(e.target.value)) && (maxInteger === null || (e.target.value <= maxInteger))) {
        setter(e.target.value)
    }
}

export function inputOnlyLowerLetters(e, setter) {
    const re = /^[a-z]*$/;
    const value = e.target.value.toLowerCase();

    if ((value === '' || re.test(value))) {
        setter(value)
    }
}

export function inputOnlyLettersAndMinus(e, setter) {
    const re = /^[a-zA-Z-]*$/;
    const value = e.target.value;

    if ((value === '' || re.test(value))) {
        setter(value)
    }
}

export function validateISOLanguageCode(value) {
    const re = /^[a-z]{2}(-[A-Z]{2})?$/;

    return (value === '' || re.test(value));
}

export function inputOnlyFloat(e, setter, maxInteger = null) {
    const re = /^\d[\d.]*$/;
    const onlyOneDot = /^[^.]*.[^.]*$/;

    if ((e.target.value === '' || (re.test(e.target.value) && onlyOneDot.test(e.target.value))) && (maxInteger === null || (e.target.value <= maxInteger))) {
        setter(e.target.value)
    }
}

export function inputOnlyFloatAndNegative(e, setter, maxInteger = null) {
    const re = /^[\d-][\d.]*$/;
    const onlyOneDot = /^[^.]*.[^.]*$/;

    if ((e.target.value === '' || (re.test(e.target.value) && onlyOneDot.test(e.target.value))) && (maxInteger === null || (e.target.value <= maxInteger))) {
        setter(e.target.value)
    }
}

export function translateViolations(violations, intl) {
    let translatedViolations = [];

    violations.forEach((item, index) => {
        translatedViolations.push(`${item.propertyPath}: ${GlobalTransIntl(item.message, intl)}`);
        translatedViolations.push(<br key={index}/>);
    });

    return translatedViolations;
}

export function hasErrorResponseViolation(error) {
    return (error.response && error.response.data && error.response.data.hasOwnProperty('violations') && error.response.data['violations'].length);
}

export function hasErrorResponseDescription(error) {
    return (error.response && error.response.data && error.response.data.hasOwnProperty('hydra:description') && error.response.data['hydra:description'].length);
}

export function getIRIFromObject($object) {
    return ($object && $object.hasOwnProperty('@id')) ? $object['@id'] : '';
}

export const selectIconComponent = () => <FontAwesomeIcon icon={faCaretDown}
                                                          className={'MuiSelect-icon MuiSelect-iconOutlined'}
                                                          size={'lg'}/>;

/**
 * Checking if loadingStatuses are done (false) and generate missingString from missing values.
 *
 * When not all loadings are finished, returns false.
 *
 * When all loadings are finished and there are all values available, it returns true.
 * Otherwise, it returns the translated missing string.
 *
 * @param loadingStatuses
 * @param loadingValues
 * @param intl
 * @returns {*|string|boolean}
 */
export function checkMultipleAxios(loadingStatuses, loadingValues, intl) {
    const addToString = (string, value) => {
        return string + ((string.length) ? ' ' + GlobalTransIntl('and', intl) + ' ' : '') + GlobalTransIntl(value, intl);
    }

    if (loadingStatuses.filter((item) => item === false).length === loadingStatuses.length) {
        let missingString = '';

        loadingValues.forEach((item) => {
            let value = item.value,
                name = item.name;

            if (!value.length && !Object.keys(value).length) {
                missingString = addToString(missingString, name);
            }
        });

        if (missingString.length) {
            return (
                GlobalTransIntl(
                    'error_placeholder_not_available',
                    intl,
                    {
                        placeholder: missingString
                    }
                ));
        }

        return true;
    }

    return false;
}

export const helperCatchErrors = (showError, intl, error) => {
    if (hasErrorResponseViolation(error)) {
        showError(translateViolations(error.response.data['violations'], intl));
    } else if (hasErrorResponseDescription(error)) {
        showError(error.response.data['hydra:description']);
    } else if (error.response && error.response['code'] === 401) {
        showError(GlobalTransIntl('jwt_expired', intl));
    } else if (error.response) {
        showError();
    }
}

export const getUrlParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get(param) || '';
}

export const removeUrlParams = () => {
    window.history.replaceState('', '', '/');
}

export const getTokenId = (token) => {
    let decodedToken = jwtDecode(token);

    return decodedToken.id;
}

export const getPinUrl = (token) => {
    return `${config.frontendUrl}?pin=${token}`;
}

export const saveValueInClipboard = (value) => {
    navigator.clipboard.writeText(value)
        .then(() => {});
}

export const getTranslationFromTranslations = (translations, localeIRI, field, defaultValue) => {
    if (localeIRI && translations.length) {
        const findTranslation = translations.find((translation => translation.language === localeIRI));

        if (findTranslation) {
            return findTranslation[field];
        }
    }

    return defaultValue;
}

export const hasRole = (roles, role) => {
    if (roles.length) {
        const findRole = roles.find(needle => needle === role);

        if (findRole) {
            return true;
        }
    }

    return false;
}

export const deepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
}

export function inputStepper(e, setter, step, min = null, max = null) {
    const re = /^\d[\d.]*$/;
    const onlyOneDot = /^[^.]*.[^.]*$/;

    if (
        e.target.value === '' ||
        (
            re.test(e.target.value) &&
            onlyOneDot.test(e.target.value) &&
            e.target.value % step === 0 &&
            (min === null || (e.target.value >= min)) &&
            (max === null || (e.target.value <= max))
        )
    ) {
        setter(e.target.value)
    }
}

export const scrollToTop = () => {
    const element = document.getElementById('main-content_container');

    if (element) {
        element.scrollIntoView({
            behavior: 'smooth'
        });
    }

    window.scrollTo(0, 0);
}