import {defineMessages} from "react-intl.macro";
import React from "react";

const messages = defineMessages({
    'document_title': {
        id: "document_title",
        defaultMessage: "Assessmentworks Admin"
    },

    // Titles
    'title_delete': {
        id: "title.delete",
        defaultMessage: "Delete"
    },
    'title_edit': {
        id: "title.edit",
        defaultMessage: "Edit"
    },
    'title_send_pin_link': {
        id: "title.send_pin_link",
        defaultMessage: "send pin link"
    },
    'title_send_browser_test_link': {
        id: "title.send.browser_test",
        defaultMessage: "send browser test link"
    },
    'title_send_test_link': {
        id: "title.send_test_link",
        defaultMessage: "send browsertest link"
    },
    'title_resend_mail': {
        id: "title.resend_mail",
        defaultMessage: "Resend E-Mail"
    },
    'title_reactivate': {
        id: "title.reactivate",
        defaultMessage: "Reactivate"
    },
    'title_result': {
        id: "title.result",
        defaultMessage: "Result"
    },
    'title_translations': {
        id: "title.translations",
        defaultMessage: "Translations"
    },
    'report_dimension_averages.edit.headline': {
        id: "report_dimension_averages.edit.headline",
        defaultMessage: "Edit report dimension averages"
    },
    'report_dimension_averages.create.headline': {
        id: "report_dimension_averages.create.headline",
        defaultMessage: "Create report dimension averages"
    },
    'pin_results_modal_headline': {
        id: 'pin.results.modal.headline',
        defaultMessage: "Result from"
    },

    // Fields
    'field_id': {
        id: "field.id",
        defaultMessage: "ID"
    },
    'field_pin': {
        id: "field.pin",
        defaultMessage: "Pin"
    },
    'field_assessment': {
        id: "field.assessment",
        defaultMessage: "Assessment"
    },
    'field_language': {
        id: "field.language",
        defaultMessage: "Language"
    },
    'field_normgroup': {
        id: "field.normgroup",
        defaultMessage: "Normgroup"
    },
    'field_created': {
        id: "field.created",
        defaultMessage: "Created"
    },
    'field_changed': {
        id: "field.changed",
        defaultMessage: "Changed"
    },
    'field_comment': {
        id: "field.comment",
        defaultMessage: "Comment"
    },
    'field_receiver': {
        id: "field.receiver",
        defaultMessage: "Receiver"
    },
    'field_actions': {
        id: "field.actions",
        defaultMessage: "Actions"
    },
    'field_action': {
        id: "field.action",
        defaultMessage: "Action"
    },
    'field_first_name': {
        id: "field.first_name",
        defaultMessage: "First name"
    },
    'field_last_name': {
        id: "field.last_name",
        defaultMessage: "Last name"
    },
    'field_invited': {
        id: "field.invited",
        defaultMessage: "Invited"
    },
    'field_email': {
        id: "field.email",
        defaultMessage: "E-Mail"
    },
    'field_date': {
        id: "field.date",
        defaultMessage: "Date"
    },
    'field_carried_out': {
        id: "field.carried_out",
        defaultMessage: "Carried out"
    },
    'field_name': {
        id: "field.name",
        defaultMessage: "Name"
    },
    'field_provided_by': {
        id: "field.provided_by",
        defaultMessage: "Provided by"
    },
    'field_status': {
        id: "field.status",
        defaultMessage: "Status"
    },
    'field_country': {
        id: "field.country",
        defaultMessage: "Country"
    },
    'field_role': {
        id: "field.role",
        defaultMessage: "Role"
    },
    'field_projects': {
        id: "field.projects",
        defaultMessage: "Projects"
    },
    'field_customer': {
        id: "field.customer",
        defaultMessage: "Customer"
    },
    'field_logo': {
        id: "field.logo",
        defaultMessage: "Logo"
    },
    'field_background_color': {
        id: "field.background_color",
        defaultMessage: "Background color"
    },
    'field_font_color': {
        id: "field.font_color",
        defaultMessage: "Font color"
    },
    'field_font_family': {
        id: "field.font_family",
        defaultMessage: "Font family"
    },
    'field_key': {
        id: "field.key",
        defaultMessage: "Key"
    },
    'field_english': {
        id: "field.english",
        defaultMessage: "English"
    },

    // Placeholder
    'placeholder_name': {
        id: "placeholder.name",
        defaultMessage: "Name"
    },

    // Buttons
    'button_reset_filter': {
        id: "button.reset.filter",
        defaultMessage: "Reset Filter"
    },
    'button_create': {
        id: "button.create",
        defaultMessage: "Create"
    },
    'button_save': {
        id: "button.save",
        defaultMessage: "Save"
    },
    'button_send': {
        id: "button.send",
        defaultMessage: "Send"
    },
    'button_submit': {
        id: "button.submit",
        defaultMessage: "Submit"
    },
    'button_back': {
        id: "button.back",
        defaultMessage: "Back"
    },
    'button_delete_active_tab': {
        id: "button.delete.active_tab",
        defaultMessage: "Delete active tab"
    },
    'button_save_module': {
        id: "button.save_module",
        defaultMessage: "Save module"
    },

    // Error
    'error_field_empty': {
        id: "error.field.empty",
        defaultMessage: "This field can't be empty"
    },
    'error_select_empty': {
        id: "error.select.empty",
        defaultMessage: "This select can't be empty"
    },
    'error_field_email': {
        id: "error.field.email",
        defaultMessage: "Email is not valid"
    },
    'error_field_number': {
        id: "error.field.email",
        defaultMessage: "This field must be a number!"
    },
    'error_placeholder_not_available': {
        id: "error.placeholder.not_available",
        defaultMessage: "{placeholder} not available!",
    },
    'error_placeholder_missing': {
        id: "error.placeholder.missing",
        defaultMessage: "{placeholder} missing!"
    },
    'jwt_expired': {
        id: "jwt.expired",
        defaultMessage: "Your Login is expired!"
    },
    'error_email': {
        id: "login.email.invalid",
        defaultMessage: "The E-Mail is not valid!"
    },
    'error_password': {
        id: "error.password",
        defaultMessage: "The password must at least have 8 characters!"
    },
    'error_password_repeat': {
        id: "error.password_repeat",
        defaultMessage: "The Password is not the same!"
    },
    'error_no_dimension': {
        id: "error.no_dimension",
        defaultMessage: "No dimension found!"
    },
    'error_no_normgroup': {
        id: "error.no_normgroup",
        defaultMessage: "No normgroup found!"
    },
    'error_no_assessment_id': {
        id: "error.no_assessment_id",
        defaultMessage: "Assessment ID missing!"
    },
    'error_no_selected_language': {
        id: "error.no_selected_language",
        defaultMessage: "Please select a language!"
    },
    'error_start_bigger_than_end': {
        id: "error.start_bigger_than_end",
        defaultMessage: "The start value can't be bigger than the end value!"
    },
    'error_invalid_language_code': {
        id: "error.invalid_language_code",
        defaultMessage: "Invalid language code!"
    },
    'error_max_file_size': {
        id: 'error.max_file_size',
        defaultMessage: "File size must not be greater than {size} Megabyte!",
    },

    // Notification
    'notification_success': {
        id: "notification.success",
        defaultMessage: "Successfully saved!"
    },
    'notification_error': {
        id: "notification.error",
        defaultMessage: "System error!{br}Please try again later.",
        values: {
            br: <br/>
        }
    },
    'notification_delete_success': {
        id: "notification.delete.success",
        defaultMessage: "Successfully deleted!"
    },
    'notification_send_success': {
        id: "notification.send.success",
        defaultMessage: "Successfully send!"
    },

    // Datagrid
    'datagrid_not_found': {
        id: "datagrid.not_found",
        defaultMessage: "No rows"
    },

    // Dialogs
    'dialog_remove': {
        id: "dialog.remove",
        defaultMessage: "Are you sure you want to remove this?"
    },
    'dialog_remove_element': {
        id: "dialog.remove.element",
        defaultMessage: "Are you sure you want to remove this element?"
    },
    'dialog_remove_placeholder': {
        id: "dialog.remove.placeholder",
        defaultMessage: "Are you sure you want to remove \"{placeholder}\"?"
    },
    'dialog_new_version': {
        id: "dialog.new_version",
        defaultMessage: "You are about to create a new version! Are you sure?"
    },

    // Module Elements
    'multiple_choice': {
        id: "multiple_choice",
        defaultMessage: "Multiple choice"
    },
    'text_input': {
        id: "text_input",
        defaultMessage: "Text input"
    },

    // Api platform error messages
    'This value is already used.': {
        id: "api_platform_error_value_used",
        defaultMessage: "This value is already used."
    },
    'At least one Reminder is needed!': {
        id: "api_platform_error_reminder_mail",
        defaultMessage: "At least one Reminder is needed!"
    },
    'Reminder mail subject and content are needed!': {
        id: "api_platform_error_reminder_one",
        defaultMessage: "Reminder mail subject and content are needed!"
    },
    'Cant be set without reminderOne!': {
        id: "api_platform_error_reminder_two_without_reminder_one",
        defaultMessage: "Cant be set without reminderOne!"
    },
    'This value should be greater than reminderOne!': {
        id: "api_platform_error_reminder_two_greater_than_reminder_one",
        defaultMessage: "This value should be greater than reminderOne!"
    },
    'This value should be greater than the last reminder!': {
        id: "api_platform_error_exclude_after",
        defaultMessage: "This value should be greater than the last reminder!"
    },
    'The value can\'t be bigger than the end value!': {
        id: "api_platform_error_report_dimension_averages_start_bigger_than_end_value",
        defaultMessage: "The value can't be bigger than the end value!"
    },

    // Roles
    'role_superadmin': {
        id: "role.superadmin",
        defaultMessage: "Superadmin"
    },
    'role_nationaladmin': {
        id: "role.nationaladmin",
        defaultMessage: "Nationaladmin"
    },
    'role_projectadmin': {
        id: "role.projectadmin",
        defaultMessage: "Projectadmin"
    },
    'role_user': {
        id: "role.user",
        defaultMessage: "User"
    },


    // Global
    'name': {
        id: "name",
        defaultMessage: "Name"
    },
    'language': {
        id: "language",
        defaultMessage: "Language"
    },
    'language_code': {
        id: "language_code",
        defaultMessage: "Language code"
    },
    'status': {
        id: "status",
        defaultMessage: "Status"
    },
    'country': {
        id: "country",
        defaultMessage: "Country"
    },
    'assessment': {
        id: "assessment",
        defaultMessage: "Assessment"
    },
    'role': {
        id: "role",
        defaultMessage: "Role"
    },
    'project': {
        id: "project",
        defaultMessage: "Project"
    },
    'customer': {
        id: "customer",
        defaultMessage: "Customer"
    },
    'loading_datagrid': {
        id: "loading.datagrid",
        defaultMessage: "Loading data!"
    },
    'day(s)': {
        id: "day(s)",
        defaultMessage: "Day(s)"
    },
    'Missing translation for': {
        id: "Missing translation for",
        defaultMessage: "Missing translation for"
    },
    'font_family': {
        id: "font_family",
        defaultMessage: "Font family"
    },
    'and': {
        id: "and",
        defaultMessage: "and"
    },
    'project_name': {
        id: "project.name",
        defaultMessage: "Project name"
    },
    'slogan_top': {
        id: "slogan.top",
        defaultMessage: "Slogan top"
    },
    'slogan_bottom': {
        id: "slogan.bottom",
        defaultMessage: "Slogan bottom"
    },
    'reminder_one': {
        id: "reminder.one",
        defaultMessage: "Reminder one"
    },
    'reminder_two': {
        id: "reminder.two",
        defaultMessage: "Reminder two"
    },
    'exclude_after': {
        id: "exclude_after",
        defaultMessage: "Exclude after"
    },
    'provided_by': {
        id: "provided_by",
        defaultMessage: "Provided by"
    },
    'receiver': {
        id: "receiver",
        defaultMessage: "Receiver"
    },
    'receiver_language': {
        id: "receiver.language",
        defaultMessage: "Receiver language"
    },
    'comment': {
        id: "comment",
        defaultMessage: "Comment"
    },
    'amount': {
        id: "amount",
        defaultMessage: "Amount"
    },
    'total_amount': {
        id: "total_amount",
        defaultMessage: "Total amount"
    },
    'no_projects': {
        id: "no_projects",
        defaultMessage: "No projects available"
    },
    'pin': {
        id: "pin",
        defaultMessage: "Pin"
    },
    'email': {
        id: "email",
        defaultMessage: "E-Mail"
    },
    'first_name': {
        id: "first_name",
        defaultMessage: "First name"
    },
    'last_name': {
        id: "last_name",
        defaultMessage: "Last name"
    },
    'full_name': {
        id: "full_name",
        defaultMessage: "Full name"
    },
    'started_at': {
        id: "started_at",
        defaultMessage: "Started at"
    },
    'token': {
        id: "token",
        defaultMessage: "Token"
    },
    'password': {
        id: "password",
        defaultMessage: "Password"
    },
    'password_repeat': {
        id: "password_repeat",
        defaultMessage: "Repeat password"
    },
    'forgot_password_notification_info': {
        id: "forgot_password.notification.info",
        defaultMessage: "When the email was found in our system, an E-Mail with the recovery code was sent to you.{br}When you don't see the E-Mail, please check your spamfolder.{br}{br}The token is valid for the next 15 minutes!",
        values: {
            br: <br/>
        }
    },
    'forgot_password_notification_success': {
        id: "forgot_password.notification.success",
        defaultMessage: "Your password was successfully updated{br}{br}You will be automatically redirected to the login in 5 seconds!",
        values: {
            br: <br/>
        }
    },
    'This value should not be blank.': {
        id: "api_platform_error_value_blank",
        defaultMessage: "This value should not be blank."
    },
    'delete': {
        id: "delete",
        defaultMessage: "Delete"
    },
    'remove': {
        id: "remove",
        defaultMessage: "Remove"
    },
    'add_receiver': {
        id: "add_receiver",
        defaultMessage: "Add receiver"
    },
    'add_normgroup': {
        id: "add_normgroup",
        defaultMessage: "Add normgroup"
    },
    'title': {
        id: "title",
        defaultMessage: "Title"
    },
    'report_subtitle': {
        id: "report.subtitle",
        defaultMessage: "Report subtitle"
    },
    'report_objective': {
        id: "report.objective",
        defaultMessage: "Report objective"
    },
    'report_objective_headline': {
        id: "report.objective.headline",
        defaultMessage: "Report objective headline"
    },
    'report_evaluation': {
        id: "report.evaluation",
        defaultMessage: "Report evaluation"
    },
    'report_evaluation_headline': {
        id: "report.evaluation.headline",
        defaultMessage: "Report evaluation headline"
    },
    'report_title': {
        id: "report.title",
        defaultMessage: "Report title"
    },
    'frontpage_headline': {
        id: "frontpage.headline",
        defaultMessage: "Frontpage headline"
    },
    'frontpage_text': {
        id: "frontpage.text",
        defaultMessage: "Frontpage text"
    },
    'modules': {
        id: "modules",
        defaultMessage: "Modules"
    },
    'add_module': {
        id: "module.add",
        defaultMessage: "Add module"
    },
    'selected_modules': {
        id: "selected.modules",
        defaultMessage: "Selected modules"
    },
    'no_modules_selected': {
        id: "no_modules_selected",
        defaultMessage: "No modules selected"
    },
    'no_new_version': {
        id: "no_new_version",
        defaultMessage: "No new version"
    },
    'version_shortcut': {
        id: "version.shortcut",
        defaultMessage: "v."
    },
    'tooltip_no_new_version': {
        id: "tooltip.no_new_version",
        defaultMessage: "With this checkbox checked the last version will be edited and no new version will be created. When you want your changes to be active for already existing Pins, you need to check this checkbox. Otherwise only new created pins will have the changes."
    },
    'clipboard_saved': {
        id: "clipboard.saved",
        defaultMessage: "Successfully saved to the clipboard!"
    },
    'module_module_timer': {
        id: "module.module_timer",
        defaultMessage: "Use module timer"
    },
    'module_module_dimension': {
        id: "module.module_dimension",
        defaultMessage: "Use module dimension"
    },
    'dimensions': {
        id: "dimensions",
        defaultMessage: "Dimensions"
    },
    'dimension': {
        id: "dimension",
        defaultMessage: "Dimension"
    },
    'time': {
        id: "time",
        defaultMessage: "Time"
    },
    'question': {
        id: "question",
        defaultMessage: "Question"
    },
    'options': {
        id: "options",
        defaultMessage: "Options"
    },
    'option': {
        id: "option",
        defaultMessage: "Option"
    },
    'edit': {
        id: "edit",
        defaultMessage: "Edit"
    },
    'yes': {
        id: "yes",
        defaultMessage: "Yes"
    },
    'no': {
        id: "no",
        defaultMessage: "No"
    },
    'page': {
        id: "page",
        defaultMessage: "Page"
    },
    'elements': {
        id: "elements",
        defaultMessage: "Elements"
    },
    'example': {
        id: "example",
        defaultMessage: "Example"
    },
    'duplicate': {
        id: "duplicate",
        defaultMessage: "Duplicate"
    },
    'grid_element': {
        id: "grid_element",
        defaultMessage: "Grid element"
    },
    'columns': {
        id: "columns",
        defaultMessage: "Columns"
    },
    'correct_answer': {
        id: "correct_answer",
        defaultMessage: "Correct answer"
    },
    'wrong_answer': {
        id: "wrong_answer",
        defaultMessage: "Wrong answer"
    },
    'text': {
        id: "text",
        defaultMessage: "Text"
    },
    'html': {
        id: "html",
        defaultMessage: "Html"
    },
    'questionnaire': {
        id: "questionnaire",
        defaultMessage: "Questionnaire"
    },
    'questions': {
        id: "questions",
        defaultMessage: "Questions"
    },
    'revert_score': {
        id: "revert_score",
        defaultMessage: "Revert score"
    },
    'questions_per_page': {
        id: "questions_per_page",
        defaultMessage: "Questions per page"
    },
    'minutes': {
        id: "minutes",
        defaultMessage: "Minutes"
    },
    'seconds': {
        id: "seconds",
        defaultMessage: "Seconds"
    },
    'construct': {
        id: "construct",
        defaultMessage: "Konstrukt"
    },
    'constructs': {
        id: "constructs",
        defaultMessage: "Konstrukte"
    },
    'scale_description': {
        id: "scale_description",
        defaultMessage: "Description of the scale"
    },
    'result': {
        id: "result",
        defaultMessage: "Result"
    },
    'interpretation': {
        id: "interpretation",
        defaultMessage: "Interpretation"
    },
    'dimensions.placeholder_description': {
        id: "dimensions.placeholder_description",
        defaultMessage: "You can use this placeholders to add the respective data"
    },
    'user_name': {
        id: "user_name",
        defaultMessage: "User name"
    },
    'correct_answers': {
        id: "correct_answers",
        defaultMessage: "Correct answers"
    },
    'solved_answers': {
        id: "solved_answers",
        defaultMessage: "Solved answers"
    },
    'max_answers': {
        id: "max_answers",
        defaultMessage: "Maximal answers"
    },
    'percent_rang': {
        id: "percent_rang",
        defaultMessage: "Percent rang"
    },
    'percent_remainder_rang': {
        id: "percent_remainder_rang",
        defaultMessage: "Percent remainder rang"
    },
    'description': {
        id: "description",
        defaultMessage: "Description"
    },
    'pdf_templates': {
        id: "pdf_templates",
        defaultMessage: "Pdf templates"
    },
    'add_template': {
        id: "add_template",
        defaultMessage: "Add template"
    },
    'selected_templates': {
        id: "selected_templates",
        defaultMessage: "Selected templates"
    },
    'no_templates_selected': {
        id: "no_templates_selected",
        defaultMessage: "No templates selected"
    },
    'normgroup': {
        id: "normgroup",
        defaultMessage: "Norm group"
    },
    'normgroups': {
        id: "normgroups",
        defaultMessage: "Normgroups"
    },
    'average': {
        id: "average",
        defaultMessage: "Average"
    },
    'standard_deviation': {
        id: "standard_deviation",
        defaultMessage: "Standard deviation"
    },
    'scala': {
        id: "scala",
        defaultMessage: "Scala"
    },
    'score': {
        id: "score",
        defaultMessage: "Score"
    },
    'score_text': {
        id: "score_text",
        defaultMessage: "Score text"
    },
    'add_score': {
        id: "add_score",
        defaultMessage: "Add score"
    },
    'size': {
        id: "size",
        defaultMessage: "Size"
    },
    'download_pdf': {
        id: "download.pdf",
        defaultMessage: "Download PDF"
    },
    'questionaire': {
        id: "questionaire",
        defaultMessage: "Questionaire"
    },
    'forced_choice': {
        id: "forced_choice",
        defaultMessage: "Forced choice"
    },
    'questionaire_weighting': {
        id: "questionaire_weighting",
        defaultMessage: "Questionaire weighting"
    },
    'forced_choice_weighting': {
        id: "forced_choice_weighting",
        defaultMessage: "Forced choice weighting"
    },
    'order_labels': {
        id: "order_labels",
        defaultMessage: "Order labels"
    },
    'order_label': {
        id: "order_label",
        defaultMessage: "Order label"
    },
    'texts': {
        id: "texts",
        defaultMessage: "Texts"
    },
    'scales': {
        id: "scales",
        defaultMessage: "Scales"
    },
    'translation_language': {
        id: "translation_language",
        defaultMessage: "Translation language"
    },
    'translation_default': {
        id: "translation_default",
        defaultMessage: "Default (English)"
    },
    'gender_salutation': {
        id: "gender_salutation",
        defaultMessage: "Gender Salutation"
    },
    'dialog_delete': {
        id: "dialog.delete",
        defaultMessage: "Are you sure you want to delete the entry?"
    },
    'start': {
        id: 'start',
        defaultMessage: "Start"
    },
    'end': {
        id: 'end',
        defaultMessage: "End"
    },
    'three_sixty_subject_focusperson': {
        id: 'three_sixty_subject_focusperson',
        defaultMessage: "Subject focusperson"
    },
    'three_sixty_subject_reference_giver': {
        id: 'three_sixty_subject_reference_giver',
        defaultMessage: "Subject reference giver"
    },
    'three_sixty_invite_focusperson': {
        id: 'three_sixty_invite_focusperson',
        defaultMessage: "Invite focusperson"
    },
    'three_sixty_invite_referencegiver': {
        id: 'three_sixty_invite_referencegiver',
        defaultMessage: "Invite reference giver"
    },
    'three_sixty_keys': {
        id: 'three_sixty_keys',
        defaultMessage: "360 degree keys"
    },
    'import': {
        id: 'import',
        defaultMessage: "Import"
    },
    'create_pin': {
        id: 'create_pin',
        defaultMessage: "Create Pin"
    },
    'import_pin': {
        id: 'import_pin',
        defaultMessage: "Import Pin"
    },
    'pin_create_export': {
        id: 'pin_create_export',
        defaultMessage: "Export pins?"
    },
    'checkbox_send_mail': {
        id: 'checkbox.send_mail',
        defaultMessage: "Send mail?"
    },
    'placeholder': {
        id: 'placeholder',
        defaultMessage: "Placeholder"
    },
    'pin_link': {
        id: 'pin_link',
        defaultMessage: "Pin link"
    },
    'three_sixty_degree': {
        id: 'three_sixty_degree',
        defaultMessage: "360 degree"
    },
    'three_sixty_pins': {
        id: 'three_sixty_pins',
        defaultMessage: "360 degree pin"
    },
    'invitation_subject': {
        id: 'invitation.subject',
        defaultMessage: "Invitation subject"
    },
    'invitation': {
        id: 'invitation',
        defaultMessage: "Invitation"
    },
    'name_focusperson': {
        id: 'name_focusperson',
        defaultMessage: "Focusperson name"
    },
    'finished': {
        id: 'finished',
        defaultMessage: "Finished"
    },
    'invitations': {
        id: 'invitations',
        defaultMessage: "Invitations"
    },
    'reminder': {
        id: 'reminder',
        defaultMessage: "Reminder"
    },
    'reminder_subject': {
        id: 'reminder.subject',
        defaultMessage: "Reminder subject"
    },
    'reminder_content': {
        id: 'reminder.content',
        defaultMessage: "Reminder content"
    },
    'result_pdf': {
        id: 'result.pdf',
        defaultMessage: "Result pdf"
    },
    'result_details': {
        id: 'result.details',
        defaultMessage: "Result details"
    },
    'introduction': {
        id: 'introduction',
        defaultMessage: "Introduction"
    },
    'three_sixty_report': {
        id: 'three_sixty.report',
        defaultMessage: "Three sixty report"
    },
    'dropdown': {
        id: 'dropdown',
        defaultMessage: "Dropdown"
    },
    'random_pages': {
        id: 'random_Pages',
        defaultMessage: "Random pages"
    },
    'disclaimer': {
        id: 'disclaimer',
        defaultMessage: "Disclaimer"
    },
    'upload': {
        id: 'upload',
        defaultMessage: "Upload"
    },
    'cover_sheet_logo': {
        id: 'cover_sheet.logo',
        defaultMessage: "Cover sheet logo"
    },
    'random_multiple_choice': {
        id: 'random_multiple_choice',
        defaultMessage: "Random multiple choice"
    },
    'add_element': {
        id: 'add_element',
        defaultMessage: "Add Element"
    },
    'max_pages': {
        id: 'max_pages',
        defaultMessage: "Max pages"
    },
    'data_protection': {
        id: 'data_protection',
        defaultMessage: "Data protection"
    },
    'result_mail': {
        id: 'result_mail',
        defaultMessage: "Result mail"
    },
    'result_mail_subject': {
        id: 'result_mail_subject',
        defaultMessage: "Result mail subject"
    },
    'result_mail_content': {
        id: 'result_mail_content',
        defaultMessage: "Result mail content"
    },
    'export': {
        id: 'export',
        defaultMessage: "Export"
    },
    'add_construct': {
        id: "add_construct",
        defaultMessage: "Add Construct"
    },
    'dimension_sorting': {
        id: "dimension_sorting",
        defaultMessage: "Dimension sorting"
    },
    'show_all_labels': {
        id: 'show_all_labels',
        defaultMessage: "Show all labels"
    },
    'right_and_wrong_choice': {
        id: 'right_and_wrong_choice',
        defaultMessage: "Right and wrong choice"
    },
    'ocs': {
        id: 'ocs',
        defaultMessage: "Ocs"
    },
    'video_record': {
        id: 'video_record',
        defaultMessage: "Video Record"
    },
    'cameratag_id': {
        id: 'cameratag_id',
        defaultMessage: "Cameratag ID"
    },
    'max_length_seconds': {
        id: 'max_length_seconds',
        defaultMessage: "Max length in seconds"
    },
    'min_length_seconds': {
        id: 'min_length_seconds',
        defaultMessage: "Min length in seconds"
    },
    'video_bitrate': {
        id: 'video_bitrate',
        defaultMessage: "Video Bit Rate"
    },
    'audio_bitrate': {
        id: 'audio_bitrate',
        defaultMessage: "Audio Bit Rate"
    },
    'width': {
        id: 'width',
        defaultMessage: "Width"
    },
    'height': {
        id: 'height',
        defaultMessage: "Height"
    },
    'bps': {
        id: 'bps',
        defaultMessage: "bps"
    },
    'auto_publish': {
        id: 'auto_publish',
        defaultMessage: "Auto publish"
    },
    'auto_finish': {
        id: 'auto_finish',
        defaultMessage: "Auto finish page"
    },
    'preroll_time': {
        id: 'preroll_time',
        defaultMessage: "Preroll time"
    },
    'is_public': {
        id: 'is_public',
        defaultMessage: "Is public"
    },
    'expire_pin_days': {
        id: 'expire_pin_days',
        defaultMessage: "Expire pin"
    },
    'force_language': {
        id: 'force_language',
        defaultMessage: "Force language"
    },
    'dont_sort_questionnaire': {
        id: 'dont_sort_questionnaire',
        defaultMessage: "Don't sort questionnaire"
    },
    'deactivate_data_protection': {
        id: 'deactivate_data_protection',
        defaultMessage: "Deactivate data protection"
    },
    'pins_in_project': {
        id: 'pins_in_project',
        defaultMessage: "Pins in Project"
    },
    'open': {
        id: 'open',
        defaultMessage: "Open"
    },
    'invited': {
        id: 'invited',
        defaultMessage: "Invited"
    },
    'started': {
        id: 'started',
        defaultMessage: "Started"
    },
    'evaluation': {
        id: 'evaluation',
        defaultMessage: "Evaluation"
    },
    'cake_color': {
        id: 'cake_color',
        defaultMessage: "Cake color"
    },
    'references': {
        id: 'references',
        defaultMessage: "References"
    },
    'mail_layout': {
        id: 'mail_layout',
        defaultMessage: "Mail layout"
    },
    'icon': {
        id: 'icon',
        defaultMessage: "Icon"
    },
    'from': {
        id: 'from',
        defaultMessage: "From"
    },
    'to': {
        id: 'to',
        defaultMessage: "To"
    },
    'subject': {
        id: 'subject',
        defaultMessage: "Subject"
    },
    'date': {
        id: 'date',
        defaultMessage: "Date"
    },
    'mail_tasks': {
        id: 'mail_tasks',
        defaultMessage: "Mail tasks"
    },
    'info': {
        id: 'info',
        defaultMessage: "Info"
    },
    'textarea': {
        id: 'textarea',
        defaultMessage: "Textarea"
    },
    'src': {
        id: 'src',
        defaultMessage: "Source"
    },
    'browser_doesnt_support_video': {
        id: 'browser.doesnt_support_video',
        defaultMessage: "Sorry, your browser doesn't support embedded videos"
    },
    'video': {
        id: 'video',
        defaultMessage: "Video"
    },
    'invitation_attachment': {
        id: 'invitation_attachment',
        defaultMessage: "Invitation mail attachment"
    },
    'domain': {
        id: 'domain',
        defaultMessage: "Domain"
    },
    'is_hidden': {
        id: 'is_hidden',
        defaultMessage: "Is hidden"
    },
    'add': {
        id: 'add',
        defaultMessage: "Add"
    },
    'adaptive_element': {
        id: 'adaptive_element',
        defaultMessage: "Adaptive Element"
    },
    'add_item': {
        id: 'add_item',
        defaultMessage: "Add Item"
    },
    'next_if_right': {
        id: 'next_if_right',
        defaultMessage: "next_if_right"
    },
    'next_if_wrong': {
        id: 'next_if_wrong',
        defaultMessage: "next_if_wrong"
    },
    'end_of_element': {
        id: 'end_of_element',
        defaultMessage: "End of Element"
    },
    'result_adaptiv_right': {
        id: 'result_adaptiv_right',
        defaultMessage: "Result if right"
    },
    'result_adaptiv_wrong': {
        id: 'result_adaptiv_wrong',
        defaultMessage: "Result if wrong"
    },
    'finish_value': {
        id: 'finish_value',
        defaultMessage: "Finish value"
    },
    'right': {
        id: 'right',
        defaultMessage: "Right"
    },
    'report': {
        id: 'report',
        defaultMessage: "Report"
    },
    'frontpage': {
        id: 'frontpage',
        defaultMessage: "Frontpage"
    },
    'report_templates': {
        id: 'report.templates',
        defaultMessage: "Report templates"
    },
    'save_statistics': {
        id: 'save_statistics',
        defaultMessage: "Save Statistics"
    },
    'cant_answer': {
        id: 'cant_answer',
        defaultMessage: "Can't answer"
    },
    'display_name': {
        id: 'display_name',
        defaultMessage: "Display name"
    },
    'templates': {
        id: 'templates',
        defaultMessage: "Templates"
    },
    'edit_template': {
        id: 'edit.template',
        defaultMessage: "Edit template"
    },
    'reminder_three_sixty_focusperson': {
        id: 'reminder.three_sixty',
        defaultMessage: "Focusperson reminder"
    },
    'reminder_three_sixty_reference_giver': {
        id: 'reminder.three_sixty',
        defaultMessage: "Reference giver reminder"
    },
    'thumbnail': {
        id: 'thumbnail',
        defaultMessage: "Thumbnail"
    },
    'deactivate_controls': {
        id: 'deactivate_controls',
        defaultMessage: "Deactivate controls"
    },
    'missing_video': {
        id: 'missing_video',
        defaultMessage: "Missing video!"
    },
    'missing_file': {
        id: 'missing_file',
        defaultMessage: "Missing file!"
    },
    'token_not_found': {
        id: 'token_not_found',
        defaultMessage: "Token not found"
    },
    'pin_already_used': {
        id: 'pin_already_used',
        defaultMessage: "Pin already used"
    },
});

export default messages;